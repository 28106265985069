import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import classNames from "classnames";
import { ContentHeader, ContentFooter } from '@components/index';

import { useSelector, useDispatch } from "react-redux";

import { RootState, modalOpenSetting, modalTypeSetting, ModalState, WindowState } from "@store/index";

import './studioLayout.scss';

const StudioLayout = () => {

  const dispatch = useDispatch();

  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const windowSize: WindowState = useSelector((state: RootState) => state.window);

  return (
    <div className="content">
      { /* content Header */}
      {/* <ContentHeader /> */}

    {/* content Body */}
      <Outlet />

      {/* content Footer */}
      <ContentFooter />

      {/* studio button */}
      {/* <div
        className={classNames(`button__studio`, { on: windowSize.bottom })}
        onClick={() => {
          dispatch(modalOpenSetting(!modal.open));
          dispatch(modalTypeSetting("createItem"));
        }}
      ></div> */}
    </div>
  )
}

export default StudioLayout;