import { useEffect, useState } from 'react';

import axios from 'axios';

import classNames from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import { Unity, useUnityContext } from "react-unity-webgl";
import { RootState, ModalState, modalOpenSetting, AlertModalState, alertModalSetting, loadingSetting } from "@store/index";

import { CheckBox, NormalButton, Loading } from '@components/index';

import { useCheckbox } from '@hooks/index';
import { REACT_APP_SERVER_URL } from '../../../shared/utils';

import '../modal.scss';

// type ConfirmModalType = "success" | "warning" | "danger";

interface CheckListProps {
  description: string
}

const CheckList = ({ description }: CheckListProps) => {
  return (
    <div className="list__check">
      <div className="check"></div>
      <p className="description">{description}</p>
    </div>
  );
}

const StudioItemModal = () => {

  const dispatch = useDispatch();

  // const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const showUv = useCheckbox(false);

  //파일 미리볼 url을 저장해줄 state
  const [fileImage, setFileImage] = useState("");
  const [file, setFile] = useState<Blob | string>("");

  const {
    unityProvider,
    isLoaded,
    sendMessage,
    requestFullscreen,
    unload,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
  } = useUnityContext({
    loaderUrl: "/unitybuild/Builds.loader.js",
    dataUrl: "/unitybuild/Builds.data",
    frameworkUrl: "/unitybuild/Builds.framework.js",
    codeUrl: "/unitybuild/Builds.wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  useEffect(() => {
    return () => {
      detachAndUnloadImmediate().catch((reason) => {
        // console.log(reason);
      });
    };
  }, [detachAndUnloadImmediate]);

  const handleClickFullscreen = () => {
    if (isLoaded === false) {
      return;
    }
    requestFullscreen(true);
  };

  // 이미지 파일 저장
  const saveFileImage = async (e:React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];
    setFileImage(URL.createObjectURL(files));
    setFile(files);
    e.target.value = '';
  };

  const imageApi = async () => {
    dispatch(loadingSetting(true));

    await axios
    .post(`${REACT_APP_SERVER_URL}/studio/avata`, {
      userid: "640838d10dc28904c39e015f",
      name: "관리자",
      gender : "female",
      select : "clothes",
      avata : file,
    }, {
      headers : {
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }
    })
    .then(({ data }) => {
      // console.log(data.response);
      // console.log(data.response.data.texture);
      sendMessage("ZemtokStudioManager", "ChangeTextureUrl", data.response.data.texture);
    })
    .catch((e) => {
      console.error(e);
    });

  dispatch(loadingSetting(false));

  }

  useEffect(() => {
    if (file !== "") {
      imageApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const downloadImg = () => {
    const image = `/unityimg/texture/${modal.studioNumber}.png`;
    const link = document.createElement("a");
    link.href = image;
    link.download = "texture.png"; //파일명
    link.click();
  }

  const handleClickUnload = async () => {
    if (isLoaded === false) {
      return;
    }
    try {
      await unload();
      console.log("Unload success");
    } catch (error) {
      console.error(`Unable to unload: ${error}`);
    }
  };

  useEffect(() => {
    sendMessage("ZemtokStudioManager", "ChangeClothesStr", modal.studioNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <div className="dim studio_item">
      <div className={classNames("modal")}>

        <div className="title__box">
          <p className="title">
            01 아이템 등록하기
            <span>02 상세 정보 등록</span>
          </p>
        </div>

        <CheckList description="템플릿을 다운로드 하고 나만의 디자인을 만들어 보세요." />
        <CheckList description="디자인한 파일을 업로드 하면 미리보기 할 수 있어요." />

        <NormalButton
          title="가이드 보기"
          buttonStyle="box"
          hierarchy="secondary_gray"
          size="sm"
          icon="leading"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            dispatch(alertModalSetting(!alert.open));
            e.preventDefault();
          }}
          buttonClassName="guide"
        />

        <div className="item__register__box">
          {/* left */}
          <div className="left">
            {!isLoaded && <Loading />}
            <Unity
              unityProvider={unityProvider}
              style={{ visibility: isLoaded ? "visible" : "hidden" }}
            />
            <div className="button--unity--fullscreen" onClick={handleClickFullscreen}></div>
          </div>
          {/* right */}
          <div className="right">
            {/* right-top */}
            <div className="top">
              <NormalButton
                title="템플릿 다운로드"
                buttonStyle="box"
                hierarchy="link_color"
                size="md"
                icon="leading"
                onClick={downloadImg}
              />
              <CheckBox name="show_uv" value={showUv.value} onClick={showUv.onClick} size="sm" label="UV 보기" />
            </div>

            {/* right-middle */}
            <div className="middle">
              {(fileImage !== undefined && fileImage !== null && fileImage !== "http://192.168.0.40:3000undefined" && fileImage !== "") ? (
                <img
                  alt="uploadImg"
                  src={fileImage}
                />
              ) : (<img src={require(`@images/unity/texture/${modal.studioNumber}.png`)} alt="texture" />)}
              {showUv.value && <img className="uv" src={require(`@images/unity/uv/${modal.studioNumber}.png`)} alt="texture" />}
            </div>
            <p className='alert'>권장 이미지 사이즈는 512X512픽셀입니다.</p>

            <div className="button--upload">
              <label className="button" htmlFor="file">업로드</label>
              <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file" />
            </div>
          </div>
        </div>

        <NormalButton
          title="다음"
          hierarchy='primary_line'
          size="xl"
          icon='trailing'
          buttonStyle='round'
          buttonClassName="next"
          disable
        />

        <div
          className="close_button"
          onClick={() => {
            handleClickUnload();
            window.scrollTo(0, 0);
            dispatch(modalOpenSetting(!modal.open));
          }}
        ></div>
      </div>
    </div>
  );
}

export default StudioItemModal;