import './registerDone.scss';
import {NormalButton} from '@components/index';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { RootState, AlertModalState, alertModalSetting } from "@store/index";

const RegisterDone = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div className="content register-done">
      <div className="done">
        <div className="done__base">
          <div className="done__wrapper">
            <div className="done__header">
              <p className="done__title">ZEMIVERSE</p>
              <div className="log-wrapper">
                <i className="log-icon check"></i>
                <p>회원가입이 완료되었습니다.</p>
              </div>
            </div>
            <div className="done__description">ZEMIVERSE의 모든 서비스를 즐겨보세요!</div>
            <div className="done-button__wrapper">
              {/* TODO: 버튼 컴포넌트로 교체 예정 */}
              <NormalButton 
                title="메인으로" 
                hierarchy="primary" 
                onClick={() => {
                  navigate(`/zemiverse`);
                }} 
                buttonStyle="round" 
                size="xl" 
                // disable={true} 
                width={isMobile? "100%" : "280px" } 
                height="48px" 
                icon="trailing" 
                buttonClassName="to_main"
              />              
              <NormalButton 
                title="마이페이지" 
                hierarchy="secondary_gray" 
                onClick={() => {
                  navigate(`/mypage`);
                }} 
                buttonStyle="round" 
                size="xl" 
                // disable={true} 
                width={isMobile ? "100%" : "280px" } 
                height="48px" 
                icon="trailing" 
                buttonClassName="my_page"
              />   
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDone;