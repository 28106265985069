import { PageTop } from '@components/common';

import '../TermsPage/terms.scss';
import { useTranslation } from 'react-i18next';

const PersonalPolicy = () => {

  const { t } = useTranslation();
{t("")}
  return (
    <div className="policy">
      <div className="policy__inner">
        <PageTop title={t("pp_title")} />
        <p>
          <br />
          <br />
          {t("pp_description")}<br />
          <br />
          <br />
          <span className='title'>{t("pp_content1_title")}</span><br />
          <br />
          {t("pp_content1_description")}
          <br />
          <br />
          <span className='title'>{t("pp_content1_content1_title")}</span><br />
          {t("pp_content1_content1_description")}<br />
          <br />
          <span className='title'>{t("pp_content1_content2_title")}</span><br />
          {t("pp_content1_content2_description")}<br />
          <br />
          <span className='title'>{t("pp_content1_content3_title")}</span><br />
          {t("pp_content1_content3_description")}<br />
          <br />
          <span className='title'>{t("pp_content1_content4_title")}</span><br />
          {t("pp_content1_content4_description")}<br />
          <br />
          <br />
          <span className='title'>{t("pp_content2_title")}</span><br />
          <br />
          {t("pp_content2_description")}
          <br /><br />
          <span className="depth1"></span>{t("pp_content2_des1")}<br />
          <span className="depth1"></span>{t("pp_content2_des2")}<br />
          <span className="depth2"></span>{t("pp_content2_des3")}<br />
          <span className="depth2"></span>{t("pp_content2_des4")}<br />
          <span className="depth2"></span>{t("pp_content2_des5")}<br />
          <span className="depth2"></span>{t("pp_content2_des6")}<br /><br />
          <span className="depth1"></span>{t("pp_content2_des7")}<br />
          <span className="depth1"></span>{t("pp_content2_des8")}<br />
          <span className="depth1"></span>{t("pp_content2_des9")}<br />
          <span className="depth1"></span>{t("pp_content2_des10")}<br /><br />
          <span className="depth1"></span>{t("pp_content2_des11")}<br /><br />

          <table>
            <thead>
              <tr>
                <th>{t("pp_content2_table_th1")}</th>
                <th>{t("pp_content2_table_th2")}</th>
                <th>{t("pp_content2_table_th3")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("pp_content2_table_tr1_td1")}</td>
                <td>{t("pp_content2_table_tr1_td2")}</td>
                <td>{t("pp_content2_table_tr1_td3")}</td>
              </tr>
              <tr>
                <td rowSpan={3}>{t("pp_content2_table_tr2_td1")}</td>
                <td>{t("pp_content2_table_tr2_td2")}</td>
                <td>{t("pp_content2_table_tr2_td3")}</td>
              </tr>
              <tr>
                <td>{t("pp_content2_table_tr3_td1")}</td>
                <td>{t("pp_content2_table_tr3_td2")}</td>
              </tr>
              <tr>
                <td>{t("pp_content2_table_tr4_td1")}</td>
                <td>{t("pp_content2_table_tr4_td2")}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content3_title")}</span><br />
          <br />
          {t("pp_content3_description")}<br />
          <br />
          <br />
          <span className='title'>{t("pp_content4_title")}</span><br /><br />
          {t("pp_content4_description")}
          <br />
          <span className='dot'></span>{t("pp_content4_dot1")}<br />
          <br />
          {t("pp_content4_des1")}
          <br />
          <span className='dot'></span>{t("pp_content4_dot2")}<br />
          <br />
          {t("pp_content4_des2")}
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content5_title")}</span><br /><br />
          {t("pp_content5_description")}
          <br />
          <br />
          
          <table>
            <thead>
              <tr>
                <th>{t("pp_content5_table_th1")}</th>
                <th>{t("pp_content5_table_th2")}</th>
                <th>{t("pp_content5_table_th3")}</th>
                <th colSpan={2}>{t("pp_content5_table_th4")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("pp_content5_table_tr1_td1")}</td>
                <td>{t("pp_content5_table_tr1_td2")}</td>
                <td>{t("pp_content5_table_tr1_td3")}</td>
                <td colSpan={2}>{t("pp_content5_table_tr1_td4")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr2_td1")}</td>
                <td>{t("pp_content5_table_tr2_td2")}</td>
                <td>{t("pp_content5_table_tr2_td3")}</td>
                <td colSpan={2}>{t("pp_content5_table_tr2_td4")}</td>
              </tr>
              <tr>
                <td rowSpan={3}>{t("pp_content5_table_tr3_td1")}</td>
                <td rowSpan={3}>{t("pp_content5_table_tr3_td2")}</td>
                <td rowSpan={3}>{t("pp_content5_table_tr3_td3")}</td>
                <td>{t("pp_content5_table_tr3_td4")}</td>
                <td>{t("pp_content5_table_tr3_td5")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr4_td1")}</td>
                <td>{t("pp_content5_table_tr4_td2")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr5_td1")}</td>
                <td>{t("pp_content5_table_tr5_td1")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr6_td1")}</td>
                <td>{t("pp_content5_table_tr6_td2")}</td>
                <td>{t("pp_content5_table_tr6_td3")}</td>
                <td colSpan={2}>{t("pp_content5_table_tr6_td4")}</td>
              </tr>
              <tr>
                <td rowSpan={6}>{t("pp_content5_table_tr7_td1")}</td>
                <td rowSpan={6}>{t("pp_content5_table_tr7_td2")}</td>
                <td>{t("pp_content5_table_tr7_td3")}</td>
                <td colSpan={2}>{t("pp_content5_table_tr7_td4")}</td>
              </tr>
              <tr>
                <td rowSpan={5}>{t("pp_content5_table_tr8_td1")}</td>
                <td>{t("pp_content5_table_tr8_td2")}</td>
                <td>{t("pp_content5_table_tr8_td3")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr9_td1")}</td>
                <td>{t("pp_content5_table_tr9_td2")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr10_td1")}</td>
                <td>{t("pp_content5_table_tr10_td2")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr11_td1")}</td>
                <td>{t("pp_content5_table_tr11_td2")}</td>
              </tr>
              <tr>
                <td>{t("pp_content5_table_tr12_td1")}</td>
                <td>{t("pp_content5_table_tr12_td2")}</td>
              </tr>
            </tbody>
          </table>     
          <br />
          <br />
          {t("pp_content5_description2")}
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content6_title")}</span><br /><br />
          {t("pp_content6_description")}
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content7_title")}</span><br /><br />
          {t("pp_content7_description")}
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content8_title")}</span><br />
          <br />
          {t("pp_content8_description")}
          <br />
          <br />

          <table>
            <thead>
              <tr>
                <th>{t("pp_content8_table_th1")}</th>
                <th>{t("pp_content8_table_th2")}</th>
                <th>{t("pp_content8_table_th3")}</th>
                <th>{t("pp_content8_table_th4")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("pp_content8_table_tr1_td1")}</td>
                <td>{t("pp_content8_table_tr1_td2")}</td>
                <td>{t("pp_content8_table_tr1_td3")}</td>
                <td>{t("pp_content8_table_tr1_td4")}</td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />
          {t("pp_content8_description2")}
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content9_title")}</span><br /><br />
          {t("pp_content9_description")}<br /><br />

          <table>
            <thead>
              <tr>
                <th>{t("pp_content9_table_th1")}</th>
                <th>{t("pp_content9_table_th2")}</th>
                <th>{t("pp_content9_table_th3")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("pp_content9_table_tr1_td1")}</td>
                <td>{t("pp_content9_table_tr1_td2")}</td>
                <td>{t("pp_content9_table_tr1_td3")}</td>
              </tr>
              <tr>
                <td>{t("pp_content9_table_tr2_td1")}</td>
                <td>{t("pp_content9_table_tr2_td2")}</td>
                <td>{t("pp_content9_table_tr2_td3")}</td>
              </tr>
              <tr>
                <td>{t("pp_content9_table_tr3_td1")}</td>
                <td>{t("pp_content9_table_tr3_td2")}</td>
                <td>{t("pp_content9_table_tr3_td3")}</td>
              </tr>
              <tr>
                <td>{t("pp_content9_table_tr4_td1")}</td>
                <td>{t("pp_content9_table_tr4_td2")}</td>
                <td>{t("pp_content9_table_tr4_td3")}</td>
              </tr>
            </tbody>
          </table>
          
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content10_title")}</span><br /><br />
          {t("pp_content10_description")}
          <br />
          <br />
          <br />
          <span className='title'>{t("pp_content11_title")}</span><br /><br />
          {t("pp_content11_description")}
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default PersonalPolicy;