import classNames from 'classnames';

import './checkbox.scss';

/**
 * @param name checkbox를 구분할 id
 * @param value checkbox의 check여부
 * @param onClick checkbox를 클릭했을 때 동작할 메서드 
 * @param size checkbox의 크기 
 */
const CheckBox = (props: any) => {

  // const _checkboxClickHandler = (checked: any) => {
  //   if(props.name==="terms-modal" || props.name==="terms") dispatch(termsSettings({terms:checked, pp:props.terms.pp }));
  //   else if(props.name==="pp-modal" || props.name==="pp") dispatch(termsSettings({pp:checked, terms:props.terms.terms }));
  // }

  return (
    <div className="checkbox">
      <div className='checkbox__wrapper'>
        <input type="checkbox" 
        id={props.name} 
        // value={props.value} 
        // onClick={(e:any)=>_checkboxClickHandler(e.target.checked)} 
        onClick={props.onClick}
        checked={props.checked} />
        <label htmlFor={props.name} className={classNames(props.size)}></label>
      </div>
      {props.label}
    </div>
  );
};

export default CheckBox;