const MODAL_SETTING = 'modal/MODAL_SETTING' as const;
const MODAL_REGISTER_SETTING = 'modal/MODAL_REGISTER_SETTING' as const;
const MODAL_REPORT_SETTING = 'modal/MODAL_REPORT_SETTING' as const;
const MODAL_OPEN_SETTING = 'modal/MODAL_OPEN_SETTING' as const;
const MODAL_TYPE_SETTING = 'modal/MODAL_TYPE_SETTING' as const;
const MODAL_IS_OK = 'modal/MODAL_IS_OK' as const;
const MODAL_STUDIO_SETTING = 'modal/MODAL_STUDIO_SETTING' as const;

type ModalAction =
  | ReturnType<typeof modalSetting>
  | ReturnType<typeof modalRegisterSetting>
  | ReturnType<typeof modalReportSetting>
  | ReturnType<typeof modalOpenSetting>
  | ReturnType<typeof modalTypeSetting>
  | ReturnType<typeof modalIsOk>
  | ReturnType<typeof modalStudioSetting>
;

export const modalSetting = (diff: {open: boolean, type: string, title: string, description: string, confirmButton: string, confirmStyle: string, onConfirm: () => void}) => ({
  type: MODAL_SETTING,
  payload: diff
});

export const modalRegisterSetting = (diff: {open: boolean, type: string}) => ({
  type: MODAL_REGISTER_SETTING,
  payload: diff
});

export const modalReportSetting = (diff: {open: boolean, type: string, userId: string}) => ({
  type: MODAL_REPORT_SETTING,
  payload: diff
});

export const modalOpenSetting = (diff: boolean) => ({
  type: MODAL_OPEN_SETTING,
  payload: diff
});

export const modalTypeSetting = (diff: string) => ({
  type: MODAL_TYPE_SETTING,
  payload: diff
});

export const modalIsOk = (diff: boolean) => ({
  type: MODAL_IS_OK,
  payload: diff
});

export const modalStudioSetting = (diff: string) => ({
  type: MODAL_STUDIO_SETTING,
  payload: diff
});

export type ModalState = {
  open: boolean;
  type?: string;
  title?: string | undefined;
  description?: string;
  confirmButton?: string;
  confirmStyle?: string;
  isOk: boolean;
  studioNumber?: string | undefined;
  onConfirm: () => void;
  // report
  userId?: string;
};

const initialState: ModalState = {
  open: false,
  type: "",
  title: "",
  description: "",
  confirmButton: "",
  confirmStyle: "",
  isOk: false,
  studioNumber: "102000100",
  onConfirm: () => {},
  userId: ""
};

function modal(
  state: ModalState = initialState,
  action: ModalAction
): ModalState {
  switch (action.type) {
    case MODAL_SETTING:
      return { ...state, open: action.payload.open, type: action.payload.type, title: action.payload.title, description: action.payload.description, confirmButton: action.payload.confirmButton, confirmStyle: action.payload.confirmStyle, onConfirm: action.payload.onConfirm };
    case MODAL_REGISTER_SETTING:
      return { ...state, open: action.payload.open, type: action.payload.type};
    case MODAL_REPORT_SETTING:
        return { ...state, open: action.payload.open, type: action.payload.type, userId: action.payload.userId};
    case MODAL_OPEN_SETTING:
      return { ...state, open: action.payload };
    case MODAL_TYPE_SETTING:
      return { ...state, type: action.payload };
    case MODAL_IS_OK: 
      return { ...state, isOk: action.payload };
    case MODAL_STUDIO_SETTING: 
      return { ...state, studioNumber: action.payload };
    default:
      return state;
  }
}

export default modal;