import { MainLayout, MetaTag, NotFound, SubLayout, StudioLayout } from "@components/index";
import LoginLayout from "@components/layout/LoginLayout/LoginLayout";
import {  Detail,  FAQ,  InquiryPage, InquiryTemporaryPage, Login, Register, RegisterDone, MyContents, Guide, StudioFAQ, Studio, InquiryWrite, TermsPage, PersonalPolicy, MyPage, Exchange, Create, Request, Calculate, News, CS} from "@pages/index";
import { RouteObject, useNavigate } from "react-router-dom";
import i18n from "@assets/locales/i18n";

const path: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <StudioLayout />,
        children: [
          { path: "studio", element: <Studio /> },
          { path: "create", element: <Create /> },
          { path: "request", element: <Request /> },
          { path: "exchange", element: <Exchange /> },
          { path: "guide", element: <Guide /> },
          { path: "calculate", element: <Calculate /> },
          { path: "news", element: <News /> },
          { path: "cs", element: <CS /> },
        ]
      },
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: 'terms', element: <TermsPage /> },
          { path: 'policy', element: <PersonalPolicy /> },
          { path: 'inquiry', element: <InquiryTemporaryPage /> },
        ]
      }
    ]
  },
  // {
  //   path: "",
  //   element: <LoginLayout />,
  //   children: [
  //     { path: "login", element: <Login /> },
  //     { path: 'register', element: <Register/> },
  //     { path: 'register/done', element: <RegisterDone /> },
  //   ]
  // },
  {
    path: "*",
    element: <LoginLayout/>,
    children: [
      { path: "*", element:<NotFound />}, 
      // { path: "test", element: <TestPage />}
    ]
  }
];

const loginPath: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <StudioLayout />,
        children: [
          { path: "studio", element: <Studio /> },
          { path: "create", element: <Create /> },
          { path: "request", element: <Request /> },
          { path: "exchange", element: <Exchange /> },
          { path: "guide", element: <Guide /> },
          { path: "calculate", element: <Calculate /> },
          { path: "news", element: <News /> },
          { path: "cs", element: <CS /> },
        ]
      },
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: 'terms', element: <TermsPage /> },
          { path: 'policy', element: <PersonalPolicy /> },
        ]
      }
    ]
  },
  {
    path: "",
    element: <LoginLayout />,
    children: [
      // { path: "login", element: <Login /> },
      // { path: 'register', element: <Register/> },
      { path: 'register/done', element: <RegisterDone /> },
      { path: 'mypage', element: <MyPage /> },
    ]
  },
  {
    path: "*",
    element: <LoginLayout/>,
    children: [
      { path: "*", element:<NotFound />}, 
      // { path: "test", element: <TestPage />}
    ]
  }
];

export { path, loginPath };