import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from 'classnames';

import { RootState, GNBState, AlertModalState, PathState, gnbSetting, alertModalSetting, WindowState } from "@store/index";
import { useDebounce } from "@hooks/index";

import './MainGnb.scss';
import { useTranslation } from "react-i18next";



const MainGnb = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);
  const path: PathState = useSelector((state: RootState) => state.path);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  

  const zemiMenu = [
    { id: 1, children: null, title: "아이템제작", link: "/create" },
    { id: 2, children: null, title: "제작의뢰", link: "/request" },
    { id: 3, children: null, title: "거래소", link: "/exchange" },
    { id: 4, children: null, title: "가이드", link: "/guide" },
    { id: 5, children: null, title: "정산관리", link: "/calculate" },
    { id: 6, children: null, title: "새소식", link: "/news" },
    { id: 7, children: null, title: "고객센터", link: "/cs" },
  ];

  const handleResize = useDebounce(() => {
    setWindowSize(window.innerWidth);
  }, 1000);

  /**
   * 현재 선택된 gnb 메뉴를 확인하기 위한 메서드 
   * 
   * @param data gnb nav 데이터 
   * @returns "on" or "off"
   */
  const checkOn = (data: any) => {
    if (!data.children) {
      return data.link === location.pathname ? "on" : "off";
    } else {
      return data.children.some((d: any) => d.link === location.pathname) ? "on" : "off";
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
  * Zemiverse Gnb Render
  */
  const Nav = (data: any, index: number) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (checkOn(data.data) === "on") {
        setOpen(true);
      }
    }, []);

    return (
      <li
        key={index}
        className={classNames(`nav_li_1deps`, checkOn(data.data), {open : (checkOn(data.data) === "on") || (open && (checkOn(data.data) === "off"))}, {close : !open})}
      >
        <p
          onClick={() => {
            if (windowSize > 1450) {
              dispatch(gnbSetting(false));
              navigate(`${data.data.link}`);
            } else {
              if (data.data.title === t("gnb_5") || data.data.title === t("gnb_9") || data.data.title === t('gnb_13')) {
                console.log(data.data.title);
                setOpen(!open);
              } else {
                dispatch(gnbSetting(false));
                navigate(`${data.data.link}`);
              }
            }
          }}
          >
          {data.data.title}
        </p>
        {data.data.children &&
          <ul 
          className={classNames(`nav_ul_2deps`, {on: (open && (checkOn(data.data) === "on") || (open && (checkOn(data.data) === "off")))})}
          >
            {data.data.children.map((d: any, idx: number) => (
              <li
                key={idx}
                className={classNames(`nav_li_2deps`, checkOn(d))}
              >
                <p
                  onClick={(e) => {
                    dispatch(gnbSetting(false));
                    navigate(`${d.link}`);
                  }}
                >
                  {d.title}
                </p>
              </li>
            ))}
          </ul>
        }
      </li>
    );
  };

  return (
    <div className={classNames("sideNav", { on: gnb.open})}>
      {/* Zemiverse */}
      <div
        className={classNames(`zemiverse_tab`, `gnb_${path.firstPath}`)}
      >
        <ul
          className={classNames(`nav_ul_1deps`)}
        >
          {
            zemiMenu.map((data: any, index: number) => (
              <Nav data={data} id={index} key={index}/>
            ))
          }
        </ul>
      </div>
    </div>
  )
}
export default MainGnb;