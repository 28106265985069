import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as InfoIcon } from '@assets/images/layer/info.svg';
import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import '../modal.scss';
import { useTranslation } from "react-i18next";

interface AlertModalProps {
  description?: string;
}

const AlertModal = ({ description }: AlertModalProps) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const alert:AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div 
      className="dim"
      onClick={() => {
        dispatch(alertModalSetting(!alert.open));
      }}
    >
      <div 
        className={classNames("modal alert")}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="left">
          <InfoIcon />
          <p className="description">{description}{t("modal_alert")}</p>
        </div>
        <div 
          className="close_button"
          onClick={() => {
            dispatch(alertModalSetting(!alert.open));
          }}
        ></div>
      </div>
    </div>
  );
}

export default AlertModal;