import { CheckBox } from "@components/common";
import './termsModalPage.scss';
import NormalButton from '@components/common/NormalButton/NormalButton';
import { useDispatch, useSelector } from "react-redux";
import { termsSettings, TermsState } from "@store/terms";
import { useTranslation } from 'react-i18next';
import { RootState } from '@store/index';
const TermsModalPage = (props: any) => {
  const { t } = useTranslation();
  const text = (
    <p>
      <br />
      <br />
      {t("terms_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content1_title")}</span><br /><br />
      {t("terms_content1_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content2_title")}</span><br /><br />
      {t("terms_content2_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content3_title")}</span><br /><br />
      {t("terms_content3_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content4_title")}</span><br /><br />
      {t("terms_content4_description1")}
      <br />
      <br />
      <span className='dot'></span>{t("terms_content4_dot1")}<br />
      <span className='dot'></span>{t("terms_content4_dot2")}<br />
      <span className='dot'></span>{t("terms_content4_dot3")}<br />
      <span className='dot'></span>{t("terms_content4_dot4")}<br />
      <span className='dot'></span>{t("terms_content4_dot5")}<br />
      <span className='dot'></span>{t("terms_content4_dot6")}<br />
      <span className='dot'></span>{t("terms_content4_dot7")}<br />
      <span className='dot'></span>{t("terms_content4_dot8")}<br />
      <br />
      {t("terms_content4_description2")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content5_title")}</span><br /><br />
      {t("terms_content5_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content6_title")}</span><br /><br />
      {t("terms_content6_description")}
      <br />
      <br />
      <span className='dot'></span>{t("terms_content6_dot1")}<br />
      <span className='dot'></span>{t("terms_content6_dot2")}<br />
      <span className='dot'></span>{t("terms_content6_dot3")}<br />
      <span className='dot'></span>{t("terms_content6_dot4")}<br />
      <span className='dot'></span>{t("terms_content6_dot5")}<br />
      <span className='dot'></span>{t("terms_content6_dot6")}<br />
      <span className='dot'></span>{t("terms_content6_dot7")}<br />
      <span className='dot'></span>{t("terms_content6_dot8")}<br />
      <span className='dot'></span>{t("terms_content6_dot9")}<br />
      <span className='dot'></span>{t("terms_content6_dot10")}<br />
      <span className='dot'></span>{t("terms_content6_dot11")}<br />
      <span className='dot'></span>{t("terms_content6_dot12")}<br />
      <span className='dot'></span>{t("terms_content6_dot13")}<br />
      <span className='dot'></span>{t("terms_content6_dot14")}<br />
      <span className='dot'></span>{t("terms_content6_dot15")}<br />
      <span className='dot'></span>{t("terms_content6_dot16")}<br />
      <span className='dot'></span>{t("terms_content6_dot17")}<br />
      <span className='dot'></span>{t("terms_content6_dot18")}<br />
      <span className='dot'></span>{t("terms_content6_dot19")}<br />
      <br />
      <br />
      <span className='title'>{t("terms_content7_title")}</span><br /><br />
      {t("terms_content7_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content8_title")}</span><br /><br />
      {t("terms_content8_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content9_title")}</span><br /><br />
      {t("terms_content9_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content10_title")}</span><br /><br />
      {t("terms_content10_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content11_title")}</span><br /><br />
      {t("terms_content11_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content12_title")}</span><br /><br />
      {t("terms_content12_description")}<br />
      <br />
      <br />
      <span className='title'>{t("terms_content13_title")}</span><br /><br />
      {t("terms_content13_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content14_title")}</span><br /><br />
      {t("terms_content14_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content15_title")}</span><br /><br />
      {t("terms_content15_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content16_title")}</span><br /><br />
      {t("terms_content16_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content17_title")}</span><br /><br />
      {t("terms_content17_description")}
      <br />
      <br />
      <br />
      <span className='title'>{t("terms_content18_title")}</span><br /><br />
      {t("terms_content18_description")}
      <br />
      <br />
      <br />
      <br />
      {t("terms_last")}
      <br />
      <br />
      {t("terms_last2")}
      <br />
      <br />
      <br />
    </p>
  );
  const dispatch = useDispatch();
  const terms: TermsState = useSelector((state: RootState) => state.terms);
  const _checkHandler = () => {
    dispatch(termsSettings({ ...terms, terms: !terms.terms }));
  }
  return (
    <div className="modal-wrapper terms">
      <div className="modal-wrapper__top">
        <div className="modal-wrapper__header"><p>{props.header}</p></div>
        <div className="modal-wrapper__scrollbar">
          {text}
        </div>
        <CheckBox
          name="terms"
          onClick={_checkHandler}
          checked={terms.terms}
          terms={terms}
          label={<p>이용약관에 동의합니다.</p>} />
      </div>
      <div className="modal-wrapper__bottom">
        <NormalButton
          title="확인"
          hierarchy="primary"
          onClick={() => { props.terms && props.ok() }}
          buttonStyle="box"
          size="md"
          disable={!props.terms}
          width="111px"
          height="40px"
        />
      </div>
    </div>
  );
};

export default TermsModalPage;