import { useState, useEffect } from "react";

import { NormalButton } from "@components/index";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import { debounce } from 'lodash';

import './studio.scss';
import { useTranslation } from "react-i18next";

export interface StepBoxProps {
  step: number,
  title: string,
  description: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const StepBox = ({ step, title, description, onClick }: StepBoxProps) => {
  return (
    <div className={classNames(`box step${step}`)}>
      <NormalButton
        title={`STEP ${step}`}
        hierarchy="secondary_gray"
        size="sm"
        buttonStyle="round"
        onClick={onClick}
      />
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
}

const Studio = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  // window width 사이즈
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // 무한 로딩 방지하고 1초에 한번씩 사이즈 측정
  const handleResize = debounce(() => {
    setWindowSize(window.innerWidth);
  }, 1000);

  // window width 사이즈 변경 감지
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="studio">
      <div className="studio__inner">

        {/* content1 */}
        <div className="content1">
          <div className="content1__inner">
            <p className="title">{t("studio_content1_title")}</p>
            <p className="description">{t("studio_content1_description")}</p>
            <NormalButton 
              title={t("studio_content1_button")}
              hierarchy="primary"
              size="txl"
              buttonStyle="round"
              onClick={() => {
                dispatch(alertModalSetting(!alert.open));
              }}
            />
          </div>
        </div>

        {/* content2 */}
        <div className="content2">
          <div className="content2__inner">
            <p className="title">{t("studio_content2_title")}</p>
            <p className="description">{t("studio_content2_description")}</p>
            <div className="boxes">
              {[...Array(3)].map((data: any, idx: number) => (
                <div className="box">
                  <p className="title">{t(`studio_content2_box${idx + 1}_title`)}</p>
                  <p className="description">{t(`studio_content2_box${idx + 1}_description`)}</p>
                  <div className="image"></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* content3 */}
        <div className="content3">
          <div className="content3__inner">
            <p className="title">{t("studio_content3_title")}</p>
            <p className="description">{t("studio_content3_description")}</p>
            <NormalButton 
              title={t("studio_content3_button")}
              hierarchy="primary"
              size="txl"
              buttonStyle="round"
              onClick={() => {
                dispatch(alertModalSetting(!alert.open));
              }}
            />
          </div>
        </div>

        {/* content4 */}
        <div className="content4">
          <div className="content4__inner">
            <div className="left"></div>
            <div className="right">
              <p className="title">{t("studio_content4_title")}</p>
              <p className="description">{t("studio_content4_description")}</p>
              <NormalButton 
              title={t("studio_content4_button")}
              hierarchy="secondary_color"
              size="txl"
              buttonStyle="round"
              icon="trailing"
              onClick={() => {
                dispatch(alertModalSetting(!alert.open));
              }}
            />
            </div>
          </div>
        </div>

        {/* content5 */}{t("")}
        <div className="content5">
          <div className="content5__inner">
            <p className="title">{t("studio_content5_title")}</p>
            <p className="description">{t("studio_content5_description")}</p>
            <div className="boxes">
              {[...Array(5)].map((data : any, idx:number) => (
                <div className="box">
                  <div className="img"></div>
                  <div className="box__inner">
                    <p className="title">{t(`studio_content5_box${idx + 1}_title`)}</p>
                    <p className="description">{t(`studio_content5_box${idx + 1}_description`)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* content6 */}
        <div className="content6">
          <div className="text__box">
            <p className="title">{t("studio_content6_title")}</p>
            <p className="description">{t("studio_content6_description")}</p>
          </div>
          <NormalButton
            title={t("studio_content6_button")}
            hierarchy="primary"
            size={windowSize > 1402 ? "txl" : windowSize > 760 ? "lg" : "xl"}
            buttonStyle="round"
            onClick={() => {
              dispatch(alertModalSetting(!alert.open));
            }}
          />
        </div>

        {/* 7 */}
        <div className="content7">
          <div className="content7__inner">
            <StepBox step={1} title={t("studio_content7_box1_title")} description={t("studio_content7_box1_description")} onClick={() => { }} />
            <div className="arrow"></div>
            <StepBox step={2} title={t("studio_content7_box2_title")} description={t("studio_content7_box2_description")} onClick={() => { }} />
            <div className="arrow"></div>
            <StepBox step={3} title={t("studio_content7_box3_title")} description={t("studio_content7_box3_description")} onClick={() => { }} />
          </div>
        </div>

      </div>
    </div>
  );
}

export default Studio;