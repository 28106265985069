import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { PageTop, NormalButton, InputBox } from "@components/index";
import useInput from "@hooks/useInput";
import axios from 'axios';

import { REACT_APP_SERVER_URL } from '@utils/config';

import { RootState, GNBState, AlertModalState, gnbSetting, alertModalSetting, SearchModalState, LoginState, loadingSetting, loginSetting, tokenSetting, profileSetting, modalSetting, ModalState, profileImgSetting } from "@store/index";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

const MyPage = () => {

  const { t } = useTranslation();

  const login: LoginState = useSelector((state: RootState) => state.login);
  const modal: ModalState = useSelector((state: RootState) => state.modal);

  const inputNickName = useInput(login.name ? login.name : "");
  const inputEmail = useInput(login.email ? login.email : "");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //파일 미리볼 url을 저장해줄 state
  const [fileImage, setFileImage] = useState(login.picture);
  const [file, setFile] = useState<Blob | string>("");

  // const [nickName, setNickName] = useState(login.name ? login.name : "");

  // 이미지 파일 저장
  const saveFileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];
    setFileImage(URL.createObjectURL(files));
    setFile(files);
    e.target.value = '';
  };

  // const getProfile = async () => {
  //   try {
  //     const { data } = await axios.post(`${REACT_APP_SERVER_URL}/user/info`, {}, {
  //       headers: {
  //         "Authorization": sessionStorage.getItem(`accessToken`)
  //       }
  //     });
  //     console.log('api profile data', data.response.data);
  //     if (data.response.data.active === false) {
  //       sessionStorage.setItem(`_id`, data.response.data._id); 
  //     } else {
  //       dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.from, email: data.response.data.email}));
  //       dispatch(loginSetting(true));
  //     }
  //   } catch (e) {
  //     console.error(e);
  //     // alert("개발팀에 문의해주세요.");
  //   }
  // };

  const getProfile = async () => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_URL}/user/id/${login._id}`, {});
      console.log('api profile data', data.response.data);
      if (data.response.data.active === false) {
        sessionStorage.setItem(`_id`, data.response.data._id); 
      } else {
        dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.social_login.from, email: data.response.data.social_login.email}));
        dispatch(loginSetting(true));
      }
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  const _handleNickname = async () => {
    console.log('check 1', login._id);
    console.log('check 222', login.accessToken);
    console.log('filefilefilefile', file);
    try {
      const { data } = await axios.put(`${REACT_APP_SERVER_URL}/user/id/${login._id}`, {
        nickname: inputNickName.value
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": login.accessToken
        }
      });
      console.log('api data', data.response.data);
      dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.social_login.from, email: data.response.data.social_login.email}));
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  const _handleProfile = async () => {
    try {
      const { data } = await axios.post(`${REACT_APP_SERVER_URL}/user/id/${login._id}/profile/upload`, {
        id: login._id,

        profile: file && file
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": login.accessToken
        }
      });
      // console.log('프로필 사진 변경 >>', data.response);
      dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.social_login.from, email: data.response.data.social_login.email}));
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  const _handleProfileUpdate = () => {
    if (inputNickName.value) {
      if (file) {
        _handleNickname();
        _handleProfile();
      } else {
        _handleNickname();
      }
    } else {
      if (file) {
        _handleProfile();
      } else {
      }
    }
    // getProfile();
  }

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mypage" style={{}}>
        <div className="mypage__inner">
          <PageTop title={t("mypage_title")} />
          <table className="box__profile">
            <tbody>
              <tr>
                <th>{t("mypage_table_tr1_th")}</th>
                <td>
                  <img className="profile" src={(fileImage !== undefined && fileImage !== null && fileImage !== `undefined` && fileImage !== "") ? fileImage : `/images/avatar2.png`} alt="" />
                  <div className="button--upload">
                    <label className="button" htmlFor="file">{t("mypage_table_button")}</label>
                    <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t("mypage_table_tr2_th")}</th>
                <td>
                  <InputBox
                    name="nickname"
                    value={inputNickName.value}
                    onChange={inputNickName.onChange}
                    placeholder="닉네임 입력"
                  />
                </td>
              </tr>
              <tr>
                <th>{t("mypage_table_tr3_th")}</th>
                <td>
                  <InputBox
                    name="nickname"
                    value={inputEmail.value}
                    onChange={inputEmail.onChange}
                    placeholder="이메일 입력"
                    disabled
                  />
                  {/* 로그인한 플랫폼 여기에 넣기 */}
                  <div className={classNames("box_platform", login.category)}>
                    <div className="icon"></div>
                    <p>{login.category} 로그인 사용</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="box__buttons">
            <NormalButton
              title={t("mypage_button1")}
              size="md"
              hierarchy="primary"
              buttonStyle="box"
              onClick={() => {
                dispatch(modalSetting({ open: !modal.open, type: "success", title: "저장하기", description: "이대로 저장하시겠습니까?", confirmButton: "저장하기", confirmStyle: "primary", onConfirm: _handleProfileUpdate }));
              }}
            />
            <NormalButton
              title={t("mypage_button2")}
              size="md"
              hierarchy="primary_line"
              buttonStyle="box"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPage;