import { useRoutes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { path, loginPath } from '@shared/config/router/path';
import { useEffect, useState } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import { encrypto } from '@utils/encrypto';
import { REACT_APP_SERVER_URL } from '@utils/config';

import { getLastPath, getFirstPath } from "@shared/utils/getLastPath";

import { pathSetting, pathFirstSetting, LoginState, RootState, loginSetting, tokenSetting, profileSetting, windowBottomSetting, windowWidthSetting, windowTopSetting, WindowState } from "@store/index";

import { debounce } from 'lodash';

import AOS from "aos";

import "aos/dist/aos.css";

const App = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  const login: LoginState = useSelector((state: RootState) => state.login);
  const windowStore: WindowState = useSelector((state: RootState) => state.window);

  const [checkLogin, setcheckLogin] = useState(path);

  // window width 사이즈
  // const [windowSize, setWindowSize] = useState(window.innerWidth);

  // 무한 로딩 방지하고 1초에 한번씩 사이즈 측정
  const handleResize = debounce(() => {
    // setWindowSize(window.innerWidth);
    document.body.style.overflow = "unset"
    console.log(window.innerWidth);
    dispatch(windowWidthSetting(window.innerWidth));
  }, 1000);

  const handleModeButton = () => {
    // console.log(window.scrollY);
    if (window.scrollY === 0) {
      dispatch(windowTopSetting(true));
    } else if (window.scrollY === (document.documentElement.scrollHeight - document.documentElement.clientHeight)) {
      dispatch(windowTopSetting(false));
      dispatch(windowBottomSetting(true));
      return;
    } else {
      dispatch(windowTopSetting(false));
      dispatch(windowBottomSetting(false));
      return;
    }
  };

  const getProfile = async (token) => {
    try {
      const { data } = await axios.post(`${REACT_APP_SERVER_URL}/user/info`, {}, {
        headers: {
          "Authorization": token
        }
      });
      console.log('api profile data', data.response.data);
      if (data.response.data.active === false) {
        sessionStorage.setItem(`_id`, data.response.data._id); 
      } else {
        getProfileExist(data.response.data._id);
      }
      
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  // 기존 유저일 경우 정보 저장
  const getProfileExist = async (id : string) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_URL}/user/id/${id}`, {

      });
      console.log('api profile data', data.response.data);
      if (data.response.data.active === false) {
        sessionStorage.setItem(`_id`, data.response.data._id); 
      } else {
        dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.social_login.from, email: data.response.data.social_login.email}));
        dispatch(loginSetting(true));
      }
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  // const handleModeButton = debounce(() => {
  //   console.log(window.scrollY);
  //   if (window.scrollY === 0) {
  //     dispatch(windowTopSetting(true));
  //   } else if (window.scrollY === (document.documentElement.scrollHeight - document.documentElement.clientHeight)) {
  //     dispatch(windowTopSetting(false));
  //     dispatch(windowBottomSetting(true));
  //     return;
  //   } else {
  //     dispatch(windowTopSetting(false));
  //     dispatch(windowBottomSetting(false));
  //     return;
  //   }
  // }, 1000);

  useEffect(() => {
    const vh = window.innerHeight / 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    dispatch(pathFirstSetting(String(getFirstPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(window.location.href);
    if (window.location.href === 'http://zemistudio.com/' || window.location.href === 'http://www.zemistudio.com/') {
      window.location.href = 'http://zemistudio.com/studio';
    } else if (window.location.href === 'https://zemistudio.com/' || window.location.href === 'https://www.zemistudio.com/') {
      window.location.href = 'http://zemistudio.com/studio';
    } else if (window.location.href === 'http://localhost:3000/') {
      window.location.href = 'http://localhost:3000/studio';
    } else if (window.location.href === 'http://192.168.0.154:3000/') {
      window.location.href = 'http://192.168.0.154:3000/studio';
    }
  }, []);

  useEffect(() => {
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    dispatch(pathFirstSetting(String(getFirstPath(location.pathname))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log('now location>>>>>',location.pathname);
    dispatch(pathSetting(String(getLastPath(location.pathname))));
    dispatch(pathFirstSetting(String(getFirstPath(location.pathname))));
    if (window.scrollY > 0) {
      // window.scrollTo(0, 0);
      // document.getElementById("root").scrollTo(0, 0);
      window.scrollTo({top: 0, left: 0});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // mode button 스크롤시 위치
  useEffect(() => {
    window.addEventListener('scroll', handleModeButton);
    return () => {
      window.removeEventListener('scroll', handleModeButton); //clean up
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(windowWidthSetting(window.innerWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  // window width 사이즈 변경 감지
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("accessToken")) {
      getProfile(sessionStorage.getItem("accessToken"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (login.login) {
      setcheckLogin(loginPath);
    } else {
      setcheckLogin(path)
    }
  }, [login]);

  useEffect(() => {
    AOS.init();
  });

  let element = useRoutes(checkLogin);
  return element;
}
export default App; 