const Studio = () => {
  return(
    <div className="studio">
      <div className="sutdio__inner">
        <p>Studio 페이지 입니다.</p>
      </div>
    </div>
  );
}

export default Studio;