import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { languages } from '@assets/locales/i18n';

import './languageBox.scss';

const LanguageBox = () => {

  const { t, i18n } = useTranslation();
  
    const toggleLocales = (locale: string) => {
      // console.log("locale", locale);
      i18n.changeLanguage(locale);
      sessionStorage.setItem('language', locale);
    }

  return(
    <div className="language_box">
      {languages.map((e: any) => <div className={classNames(`lang`, e === i18n.language ? "on" : "")} key={e} onClick={(index) => toggleLocales(e)}>{t(e)}</div>)}
    </div>
  );
}

export default LanguageBox;