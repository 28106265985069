import { LanguageBox } from "@components/index";

import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import { ReactComponent as InstagramIcon } from '@assets/images/sns/instagram.svg';
import { ReactComponent as TwitterIcon } from '@assets/images/sns/twitter.svg';
import { ReactComponent as YoutubeIcon } from '@assets/images/sns/youtube.svg';
import { ReactComponent as TiktokIcon } from '@assets/images/sns/tiktok.svg';

import './contentFooter.scss';
import { useTranslation } from "react-i18next";

type SnsButtonProps = {
  title: string,
  url: string,
  onClick: React.MouseEventHandler<HTMLLIElement>;
}

const SnsButton = ({ title, url, onClick }: SnsButtonProps) => {
  return (
    <li
      className={classNames("sns_button", title)}
      onClick={onClick}
    >
      {title === "instagram" ? (
        <InstagramIcon />
      ) : title === "twitter" ? (
        <TwitterIcon />
      ) : title === "youtube" ? (
        <YoutubeIcon />
      ) : title === "tiktok" ? (
        <TiktokIcon />
      ) : null}
    </li>
  );
}


const ContentFooter = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div className="content_footer">
      <div className="content_footer__inner">
        <div className="logo"
          onClick={() => {
            navigate(`/studio`);
          }}
        ></div>
        <div className="box">
          <div className="left">
            <ul className="footer_menu">
              <li
                onClick={() => {
                  navigate('/terms');
                }}
              >{t("footer_menu1")}</li>
              <li
                onClick={() => {
                  navigate('/policy');
                }}
              >{t("footer_menu2")}</li>
              <li
                onClick={() => {
                  navigate('/inquiry');
                }}
              >{t("footer_menu3")}</li>
            </ul>
            <p className="copyright">{t("footer_copy")}</p>
          </div>
          <div className="right">
            <ul className="sns_box">
              <SnsButton title="instagram" url="https://www.instagram.com" onClick={() => {
                dispatch(alertModalSetting(!alert.open));
              }} />
              <SnsButton title="youtube" url="https://www.youtube.com" onClick={() => {
                dispatch(alertModalSetting(!alert.open));
              }} />
              <SnsButton title="tiktok" url="https://www.tiktok.com" onClick={() => {
                dispatch(alertModalSetting(!alert.open));
              }} />
            </ul>

            <LanguageBox />
          </div>
        </div>
      </div>

    </div>
  )
}
export default ContentFooter;