export interface PageTopProps {
  title: string;
  description?: string;
}

const PageTop = ({title, description} : PageTopProps) => {
  return(
    <div className="page_top">
      <p className="title">{title}</p>
      {description && <p className="description">{description}</p>}
    </div>
  );
}

export default PageTop;