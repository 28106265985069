import { NormalButton, SelectBox } from '@components/index';

import useSelect from '@hooks/useSelect';
import { useSelector, useDispatch } from "react-redux";

import { PageTop } from '@components/common/board';
import { RootState, PathState, ModalState, modalReportSetting, LoginState, modalSetting, modalOpenSetting } from "@store/index";

import '../modal.scss';
import { useEffect, useState } from 'react';

const ReportModal = () => {

  const dispatch = useDispatch();

  const CategoryList = [
    {id : 1, title : "불건전 언어 사용"},
    {id : 2, title : "잘못된 정보"},
    {id : 3, title : "사칭 및 사기"},
    {id : 4, title : "도배"},
  ];

  const selectData = useSelect(CategoryList[0]);

  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const login: LoginState = useSelector((state: RootState) => state.login);

  const [date, setDate] = useState<Date | string>(new Date());

  const handleConfirmClick = () => {
    modal.onConfirm();
    dispatch(modalOpenSetting(!modal.open));
  };

  return(
    <div className="dim">
      <div className="modal report">
        <div 
          className="close_button"
          onClick={() => {
            dispatch(modalOpenSetting(!modal.open))
          }}
        ></div>
        <PageTop title="신고하기"/>
        <p className="description">
          신고사유 선택 후 상세한 신고 내용을 함께 작성해 주시면 확인에 큰 도움이 됩니다.<br />
          허위신고 시 운영정책에 의거하여 제재될 수 있습니다.
        </p>
        <table>
          <tbody>
            <tr>
              <th>신고자</th>
              <td>{login.name}</td>
            </tr>
            <tr>
              <th>신고일</th>
              <td>{String(date.toLocaleString())}</td>
            </tr>
            <tr>
              <th>신고 대상자</th>
              <td>{modal.userId}</td>
            </tr>
            <tr>
              <th>신고사유</th>
              <td>
              <SelectBox 
                name="category"
                data={CategoryList}
                selectData={selectData}
                classification
              />
              </td>
            </tr>
            <tr>
              <th>신고내용</th>
              <td>
                <textarea
                  placeholder="신고내용을 입력해주세요.
                  (최대 50자 이내)"
                  style={{ resize: "none" }}
                  maxLength={50}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_box">
          <NormalButton 
            title="신고하기"
            size="xl"
            hierarchy="primary"
            buttonStyle="box"
            onClick={() => {
              dispatch(modalSetting({open: modal.open, type: "warning", title: "신고하기" , description: "정말로 신고 하시겠습니까?", confirmButton: "신고하기", confirmStyle: "primary", onConfirm: handleConfirmClick }))
            }}
          />
          <NormalButton 
            title="취소"
            size="xl"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => {
              dispatch(modalReportSetting({ open: !modal.open, type: "", userId: "" }));
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ReportModal;